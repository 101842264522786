body,
html,
#root {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: auto;
    top: 0;
    bottom: auto;
    margin: 0;
    padding: 0;
    background: rgb(245, 245, 245);
    color: white;
    touch-action: none;
    overflow: -moz-scrollbars-vertical; 
    overflow-x: scroll;
}

@font-face {
    font-family: RobotoFont;
    src: url(./assets/fonts/Roboto.ttf);
}

@font-face {
    font-family: RobotoRegularFont;
    src: url(./assets/fonts/Roboto-Regular.ttf);
}


.header {
    display: block;
    position: fixed;
    background-color: white;
    width: 100vw;
    height: 1;
    top: 0px;
    bottom: auto;
    left: 0px;
}

.headerButtons {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;


}

.headerButtons button {
    font-family: RobotoRegularFont;
    letter-spacing: 0.25vw;
    font-size: 0.7rem;
    /* font-weight: 900; */
    border: none;
    background-color: none;
    background-color: #ffffff;
    padding: 1vw;
    text-decoration: none;
    color: black;
}

.headerButtons button a {
    text-decoration: none;
    color: black;
}


.modal {
    display: block;
    position: fixed;
    top: 10vh;
    bottom: auto;
    left: 10vw;
    right: auto;
    /* color: #b5b8e8; */
    width: 80vw;
    height: 80vh;
    border: 4px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    border: 4px solid white;
    backdrop-filter: blur(10px);
    filter: drop-shadow(0 1mm 1mm rgb(255, 255, 255, 0.5));
    font-family: RobotoFont;
    letter-spacing: 0.4vw;
}


.homepage {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.homepage-image {
    display: flex;
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
}

.homepage-image div {
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    bottom: 5%;
    left: 5%;
    border-radius: 100%;
    opacity: 0.1;
    filter: drop-shadow(0 6mm 10mm rgb(255, 236, 252));
    animation: spin 5s linear infinite;
}

.homepage-image img {
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    bottom: 5%;
    border-radius: 100%;
    opacity: 1;
}

.homepage-text {
    display: flex;
    color: black;
    font-size: 1.1rem;
    /* font-weight: 100; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* font-weight: bold; */
    width: 14rem;

 

}

.job {
    color: #7ec0bc;
}

.description {
    font-family: RobotoRegularFont;
    padding-top: 1rem;
    font-size:  0.7rem;
    letter-spacing: 0.15rem;
    line-height: 0.9rem;
    color: black;

}

@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

.titleScroll {
    font-size: 5px;   
    width: 100px;
    height: 10vw;
    /* background-color: red; */
    overflow: hidden;
}

.titleScrollSlider { 
    width: 2000px;
    display: flex;
    flex-direction: row;
    padding-left: 1vw;
}

.titleScrollSlider h1 {
    display: flex;
    width: 100px;
    height: 7vw;
    align-items: center;
}

.modal-aboutMe {
    display: block;
    position: fixed;
    top: 10vh;
    bottom: auto;
    left: 10vw;
    right: auto;
    /* color: #b5b8e8; */
    width: 80vw;
    height: auto;
    border: 4px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    border: 4px solid white;
    backdrop-filter: blur(10px);
    filter: drop-shadow(0 1mm 1mm rgb(255, 255, 255, 0.5));
    font-family: RobotoFont;
    letter-spacing: 0.15vw;
}


.aboutMe {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: auto;
}
.aboutMe-title {
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;

    letter-spacing: 0.5vw;
}

.aboutMe-text {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    font-size: 0.8rem;

    
}

@media (max-aspect-ratio: 1) {
    .homepage {
        flex-direction: column; 
    }
  }

.moreInfo h1 {
    font-size:  1rem;
    
    
}



.moreInfo div {
    width: 80%
}

.card {
    background-color: red;
}


.video { 
    width: 640px;
    height: 365px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: RobotoFont;
    background-color: none;
    font-size:  0.7rem;
    touch-action: none;
}


.moreInfo {
    width: 640px;
    height: 365px;
    border: 4px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    filter: drop-shadow(0 1mm 1mm rgb(255, 255, 255, 0.5));
    font-family: RobotoFont;
    letter-spacing: 0.15vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    font-size:  0.8rem;
    touch-action: none;
    
}